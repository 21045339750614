/*
    Retina ready styles
*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5){
    .accordion.default .title::before{
        background-image: url('../../theme-images/accordion-closed@2x.png');
        background-size: 13px 13px;
    }

    .search-submit{
        background-image: url('../../theme-images/search@2x.png');
        background-size: 15px 15px;
    }

    .header-type-2.dark .search-submit{
        background-image: url('../../theme-images/search-white@2x.png');
        background-size: 15px 15px;
    }

    .widget_search .search-submit{
        background-image: url('../../theme-images/search@2x.png');
        background-size: 15px 15px;
    }

    .newsletter .submit{
        background-image: url('../../theme-images/email@2x.png');
        background-size: 14px 11px;
    }

    .accordion.default .title::before{
        background-image: url('../../theme-images/accordion-closed@2x.png');
        background-size: 13px 13px;
    }

    .accordion.default .title.active::before, 
    .accordion.default .title.active:hover::before{
        background-image: url('../../theme-images/accordion-opened@2x.png');
        background-size: 13px 13px;
    }

    .accordion.default .title:hover::before{
        background-image: url('../../theme-images/accordion-closed-hover@2x.png');
        background-size: 13px 13px;
    }

    .accordion.default.simple .title::before{
        background-image: url('../../theme-images/accordion-closed@2x.png');
        background-size: 13px 13px;
    }

    .accordion.default.simple .title.active::before{
        background-image: url('../../theme-images/accordion-opened@2x.png');
        background-size: 13px 13px;
    }

    .accordion.default.simple .title:hover::before{
        background-image: url('../../theme-images/accordion-closed-hover@2x.png');
        background-size: 13px 13px;
    }

    .scroll-up{
        background-image: url('../../theme-images/to-top@2x.png');
        background-size: 40px 40px;
    }

    .post-timeline{
        background-image: url('../../theme-images/timeline@2x.png');
        background-size: 1px 1px;
    }

    .widget_nav_menu .sub-menu .menu-item{
        background-image: url('../../theme-images/aside@2x.png');
        background-size: 4px 6px;
    }

    #coming-soon .newsletter .submit{
        background-image: url('../../theme-images/email@2x.png');
        background-size: 14px 11px;
    }

    .error-box p{
        background: url('../../theme-images/error-box@2x.png') no-repeat 0 center;
        background-size: 20px 20px;
    }

    .success-box p{
        background: url('../../theme-images/success-box@2x.png') no-repeat 0 center;
        background-size: 20px 20px;
    }

    .warning-box p{
        background: url('../../theme-images/warning-box@2x.png') no-repeat 0 center;
        background-size: 20px 20px;
    }

    .infobox p{
        background: url('../../theme-images/info-box@2x.png') no-repeat 0 center;
        background-size: 20px 20px;
    }

    .comment .comment-reply-link{
        background: url('../../theme-images/icon-reply@2x.png') no-repeat 0 center;
        background-size: 14px 14px;
    }

    .tweet-list.twitter-logo li {
        background: url('../../theme-images/tweet@2x.png') no-repeat;
        background-size: 23px 17px;
    }
}

