@font-face {
	font-family: 'pixons';
	src:url('../fonts/pixons.eot');
	src:url('../fonts/pixons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/pixons.woff') format('woff'),
		url('../fonts/pixons.ttf') format('truetype'),
		url('../fonts/pixons.svg#pixons') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'pixons';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="pixons-"]:before {
*/
.pixons-amazon:before, .pixons-android:before, .pixons-aol:before, .pixons-behance:before, .pixons-bing:before, .pixons-blogger:before, .pixons-buzz:before, .pixons-delicious:before, .pixons-deviantart:before, .pixons-digg:before, .pixons-dribbble:before, .pixons-dropbox:before, .pixons-drupal:before, .pixons-ember:before, .pixons-envato:before, .pixons-evernote:before, .pixons-facebook-1:before, .pixons-facebook-2:before, .pixons-feedburner:before, .pixons-forrst:before, .pixons-foursquare:before, .pixons-github:before, .pixons-google_plus:before, .pixons-grooveshark:before, .pixons-html5:before, .pixons-instagram:before, .pixons-lastfm:before, .pixons-linkedin:before, .pixons-metacafe:before, .pixons-mixx:before, .pixons-myspace:before, .pixons-newsvine:before, .pixons-paypal:before, .pixons-picasa:before, .pixons-pinterest:before, .pixons-plixi:before, .pixons-plurk:before, .pixons-posterous:before, .pixons-reddit:before, .pixons-rss:before, .pixons-sharethis:before, .pixons-skype:before, .pixons-stumbleupon:before, .pixons-technorati:before, .pixons-tumblr:before, .pixons-twitter-1:before, .pixons-twitter-2:before, .pixons-vimeo:before, .pixons-wordpress:before, .pixons-xing:before, .pixons-yahoo:before, .pixons-yelp:before, .pixons-youtube:before, .pixons-zerply:before, .pixons-zootool:before, .pixons-github-2:before, .pixons-soundcloud:before, .pixons-flickr:before, .pixons-apple:before, .pixons-tux:before {
	font-family: 'pixons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
.pixons-amazon:before {
	content: "\21";
}

.pixons-amazon.pixons-color:hover:before{
    color: #FE8500;
}

.pixons-amazon.pixons-background:hover{
    background-color: #FE8500;
}

.pixons-android:before {
	content: "\22";
}

.pixons-android.pixons-color:hover:before{
    color: #A5C63B;
}

.pixons-android.pixons-background:hover{
    background-color: #A5C63B;
}

.pixons-aol:before {
	content: "\23";
}

.pixons-aol.pixons-color:hover:before{
    color: #FFCD00;
}

.pixons-aol.pixons-background:hover{
    background-color: #FFCD00; 
}

.pixons-behance:before {
	content: "\24";
}

.pixons-behance.pixons-color:hover:before{
    color: #0093FB;
}

.pixons-behance.pixons-background:hover{
    background-color: #0093FB;
}

.pixons-bing:before {
	content: "\25";
}

.pixons-bing.pixons-color:hover:before{
    color: #0074BD;
}

.pixons-bing.pixons-background:hover{
    background-color: #0074BD;
}

.pixons-blogger:before {
	content: "\26";
}

.pixons-blogger.pixons-color:hover:before{
    color: #FE6700;
}

.pixons-blogger.pixons-background:hover{
    background-color: #FE6700;
}

.pixons-buzz:before {
	content: "\27";
}

.pixons-buzz.pixons-color:hover:before{
    color: #F89418;
}

.pixons-buzz.pixons-background:hover{
    background-color: #F89418;
}

.pixons-delicious:before {
	content: "\28";
}

.pixons-delicious.pixons-color:hover:before{
    color: #0049CC;
}

.pixons-delicious.pixons-background:hover{
    background-color: #0049CC;
}

.pixons-deviantart:before {
	content: "\29";
}

.pixons-deviantart.pixons-color:hover:before{
    color: #BCD224;
}

.pixons-deviantart.pixons-background:hover{
    background-color: #BCD224;
}

.pixons-digg:before {
	content: "\2a";
}

.pixons-digg.pixons-color:hover:before{
    color: #1B5791;
}

.pixons-digg.pixons-background:hover{
    background-color: #1B5791;
}

.pixons-dribbble:before {
	content: "\2b";
}

.pixons-dribbble.pixons-color:hover:before{
    color: #F46899;
}

.pixons-dribbble.pixons-background:hover{
    background-color: #F46899;
}

.pixons-dropbox:before {
	content: "\2c";
}

.pixons-dropbox.pixons-color:hover:before{
    color: #008BD3;
}

.pixons-dropbox.pixons-background:hover{
    background-color: #008BD3;
}

.pixons-drupal:before {
	content: "\2d";
}

.pixons-drupal.pixons-color:hover:before{
    color: #0272BA;
}

.pixons-drupal.pixons-background:hover{
    background-color: #0272BA;
}

.pixons-ember:before {
	content: "\2e";
}

.pixons-ember.pixons-color:hover:before{
    color: #E52A01;
}

.pixons-ember.pixons-background:hover{
    background-color: #E52A01;
}

.pixons-envato:before {
	content: "\2f";
}

.pixons-envato.pixons-color:hover:before{
    color: #82B53F;
}

.pixons-envato.pixons-background:hover{
    background-color: #82B53F;
}

.pixons-evernote:before {
	content: "\30";
}

.pixons-evernote.pixons-color:hover:before{
    color: #79D626;
}

.pixons-evernote.pixons-background:hover{
    background-color: #79D626;
}

.pixons-facebook-1:before {
	content: "\31";
}
.pixons-facebook-2:before {
	content: "\32";
}

.pixons-facebook-1.pixons-color:hover:before, 
.pixons-facebook-2.pixons-color:hover:before{
    color: #3C5A98;
}

.pixons-facebook-1.pixons-background:hover,
.pixons-facebook-2.pixons-background:hover{
    background-color: #3C5A98;
}

.pixons-feedburner:before {
	content: "\33";
}

.pixons-feedburner.pixons-color:hover:before{
    color: #0078C4;
}

.pixons-feedburner.pixons-background:hover{
    background-color: #0078C4;
}

.pixons-foursquare:before {
	content: "\35";
}

.pixons-foursquare.pixons-color:hover:before{
    color: #00ADF1;
}

.pixons-foursquare.pixons-background:hover{
    background-color: #00ADF1;
}

.pixons-github:before {
	content: "\36";
}

.pixons-github.pixons-color:hover:before, 
.pixons-github-2.pixons-color:hover:before{
    color: #000000;
}

.pixons-github.pixons-background:hover,
.pixons-github-2.pixons-background:hover{
    background-color: #000;
}

.pixons-google_plus:before {
	content: "\37";
}

.pixons-google_plus.pixons-color:hover:before{
    color: #E13835;
}

.pixons-google_plus.pixons-background:hover{
    background-color: #E13835;
}

.pixons-grooveshark:before {
	content: "\38";
}

.pixons-grooveshark.pixons-color:hover:before{
    color: #000000;
}

.pixons-grooveshark.pixons-background:hover{
    background-color: #000000;
}

.pixons-html5:before {
	content: "\39";
}

.pixons-html5.pixons-color:hover:before{
    color: #E34E26;
}

.pixons-html5.pixons-background:hover{
    background-color: #E34E26;
}

.pixons-instagram:before {
	content: "\3a";
}

.pixons-instagram.pixons-color:hover:before{
    color: #6D5346;
}

.pixons-instagram.pixons-background:hover{
    background-color: #6D5346;
}

.pixons-lastfm:before {
	content: "\3b";
}

.pixons-lastfm.pixons-color:hover:before{
    color: #D21309;
}

.pixons-lastfm.pixons-background:hover{
    background-color: #D21309;
}

.pixons-linkedin:before {
	content: "\3c";
}

.pixons-linkedin.pixons-color:hover:before{
    color: #007BB6;
}

.pixons-linkedin.pixons-background:hover{
    background-color: #007BB6;
}

.pixons-metacafe:before {
	content: "\3d";
}

.pixons-metacafe.pixons-color:hover:before{
    color: #FD8019;
}

.pixons-metacafe.pixons-background:hover{
    background-color: #FD8019;
}

.pixons-mixx:before {
	content: "\3e";
}

.pixons-mixx.pixons-color:hover:before{
    color: #9F1C24;
}

.pixons-mixx.pixons-background:hover{
    background-color: #9F1C24;
}

.pixons-myspace:before {
	content: "\3f";
}

.pixons-myspace.pixons-color:hover:before{
    color: #6E85BA;
}

.pixons-myspace.pixons-background:hover{
    background-color: #6E85BA;
}

.pixons-newsvine:before {
	content: "\40";
}

.pixons-newsvine.pixons-color:hover:before{
    color: #005B21
}

.pixons-newsvine.pixons-background:hover{
    background-color: #005B21;
}

.pixons-paypal:before {
	content: "\41";
}

.pixons-paypal.pixons-color:hover:before{
    color: #002F84;
}

.pixons-paypal.pixons-background:hover{
    background-color: #002F84;
}

.pixons-picasa:before {
	content: "\42";
}

.pixons-picasa.pixons-color:hover:before{
    color: #9B479F;
}

.pixons-picasa.pixons-background:hover{
    background-color: #9B479F;
}

.pixons-pinterest:before {
	content: "\43";
}

.pixons-pinterest.pixons-color:hover:before{
    color: #E52A33;
}

.pixons-pinterest.pixons-background:hover{
    background-color: #E52A33;
}

.pixons-plixi:before {
	content: "\44";
}

.pixons-plixi.pixons-color:hover:before{
    color: #3AB2E7;
}

.pixons-plixi.pixons-background:hover{
    background-color: #3AB2E7;
}

.pixons-plurk:before {
	content: "\45";
}

.pixons-plurk.pixons-color:hover:before{
    color: #E74747;
}

.pixons-plurk.pixons-background:hover{
    background-color: #E74747;
}

.pixons-posterous:before {
	content: "\46";
}

.pixons-posterous.pixons-color:hover:before{
    color: #FEE480;
}

.pixons-posterous.pixons-background:hover{
    background-color: #FEE480;
}

.pixons-reddit:before {
	content: "\47";
}

.pixons-reddit.pixons-color:hover:before{
    color: #FE0606;
}

.pixons-reddit.pixons-background:hover{
    background-color: #FE0606;
}

.pixons-rss:before {
	content: "\48";
}

.pixons-rss.pixons-color:hover:before{
    color: #FE9900;
}

.pixons-rss.pixons-background:hover{
    background-color: #FE9900;
}

.pixons-sharethis:before {
	content: "\49";
}

.pixons-sharethis.pixons-color:hover:before{
    color: #008852;
}

.pixons-sharethis.pixons-background:hover{
    background-color: #008852;
}

.pixons-skype:before {
	content: "\4a";
}

.pixons-skype.pixons-color:hover:before{
    color: #01AEF2;
}

.pixons-skype.pixons-background:hover{
    background-color: #01AEF2;
}

.pixons-stumbleupon:before {
	content: "\4b";
}

.pixons-stumbleupon.pixons-color:hover:before{
    color: #EA4822;
}

.pixons-stumbleupon.pixons-background:hover{
    background-color: #EA4822;
}

.pixons-technorati:before {
	content: "\4c";
}

.pixons-technorati.pixons-color:hover:before{
    color: #1EC11D;
}

.pixons-technorati.pixons-background:hover{
    background-color: #1EC11D;
}

.pixons-tumblr:before {
	content: "\4d";
}

.pixons-tumblr.pixons-color:hover:before{
    color: #2F4C6A;
}

.pixons-tumblr.pixons-background:hover{
    background-color: #2F4C6A;
}

.pixons-twitter-1:before {
	content: "\4e";
}

.pixons-twitter-2:before {
	content: "\4f";
}

.pixons-twitter-1.pixons-color:hover:before, 
.pixons-twitter-2.pixons-color:hover:before{
    color: #32CCFE;
}

.pixons-twitter-1.pixons-background:hover,
.pixons-twitter-2.pixons-background:hover{
    background-color: #32CCFE;
}

.pixons-vimeo:before {
	content: "\50";
}

.pixons-vimeo.pixons-color:hover:before{
    color: #17B3E8;
}

.pixons-vimeo.pixons-background:hover{
    background-color: #17B3E8;
}

.pixons-wordpress:before {
	content: "\51";
}

.pixons-wordpress.pixons-color:hover:before{
    color: #22769B;
}

.pixons-wordpress.pixons-background:hover{
    background-color: #22769B;
}

.pixons-xing:before {
	content: "\52";
}

.pixons-xing.pixons-color:hover:before{
    color: #22769B;
}

.pixons-xing.pixons-background:hover{
    background-color: #22769B;
}

.pixons-yahoo:before {
	content: "\53";
}

.pixons-yahoo.pixons-color:hover:before{
    color: #7B008B;
}

.pixons-yahoo.pixons-background:hover{
    background-color: #7B008B;
}

.pixons-yelp:before {
	content: "\54";
}

.pixons-yelp.pixons-color:hover:before{
    color: #DD1700;
}

.pixons-yelp.pixons-background:hover{
    background-color: #DD1700;
}

.pixons-youtube:before {
	content: "\55";
}

.pixons-youtube.pixons-color:hover:before{
    color: #DA2625;
}

.pixons-youtube.pixons-background:hover{
    background-color: #DA2625;
}

.pixons-zerply:before {
	content: "\56";
}

.pixons-zerply.pixons-color:hover:before{
    color: #9CBC7B;
}

.pixons-zerply.pixons-background:hover{
    background-color: #9CBC7B;
}

.pixons-zootool:before {
	content: "\57";
}

.pixons-zootool.pixons-color:hover:before{
    color: #7AAA46;
}

.pixons-zootool.pixons-background:hover{
    background-color: #7AAA46;
}

.pixons-github-2:before {
	content: "\58";
}

.pixons-soundcloud:before {
	content: "\59";
}

.pixons-soundcloud.pixons-color:hover:before{
    color: #FF3800;
}

.pixons-soundcloud.pixons-background:hover{
    background-color: #FF3800;
}

.pixons-flickr:before {
	content: "\5a";
}

.pixons-flickr.pixons-color:hover:before{
    color: #FF0080;
}

.pixons-flickr.pixons-background:hover{
    background-color: #FF0080;
}

.pixons-apple:before {
	content: "\5b";
}

.pixons-apple.pixons-color:hover:before{
    color: #656565;
}

.pixons-apple.pixons-background:hover{
    background-color: #656565;
}

.pixons-tux:before {
	content: "\5c";
}

.pixons-tux.pixons-color:hover:before{
    color: #F5DB40;
}

.pixons-tux.pixons-background:hover{
    background-color: #F5DB40;
}

.pixons-tumblr.pixons-color:hover:before{
    color: #2C4762;
}

.pixons-tumblr.pixons-background:hover{
    background-color: #2C4762;
}

.pixons-background{
    width: 30px;
    height: 30px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.pixons-background:hover{
    color: #fff !important;
}
