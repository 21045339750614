/*
    Default blue color styles - You can change this color to any color you wanr
*/

a:hover, 
#header-wrapper #header.dark #top-bar-wrapper #top-bar a:hover, 
#header-wrapper #header.dark #top-bar-wrapper #top-bar i, 
#header .navbar-default .navbar-nav > .current-menu-item > a,
#header.dark .dropdown-menu > li.current-menu-item > a,
#header.dark .pi-mm-list > li.current-menu-item > a,
#header .dropdown-menu > li.current-menu-item > a,
#header .pi-mm-list > li.current-menu-item > a,
#header .navbar-default .navbar-nav > li>a:hover, 
#header .navbar-default .navbar-nav > li >a:focus, 
#header .pi-mm-list > li > a:hover, pi-mm-list > li > a:focus, 
#header.dark .pi-mm-list > li > a:hover, pi-mm-list > li > a:focus
#header .dropdown-menu > li > a:hover, 
#header .dropdown-menu > li > a:focus, 
#header.dark .dropdown-menu > li > a:hover, 
#header.dark .dropdown-menu > li > a:focus, 
#header .dropdown.dropdown-submenu > a:hover::after,
#header.dark .dropdown.dropdown-submenu > a:hover::after,
#header.header-type-1 .dropdown-menu > li > a:hover,
#header.header-type-1 .dropdown-menu > li > a:focus,
.page-title-1 .breadcrumbs-container .breadcrumb li a:hover, 
.page-title-2 .breadcrumbs-container .breadcrumb li a:hover,
strong, 
strong a, 
.fancy-heading span, 
.footer-widget-container .widget a:hover, 
#copyright-container .footer-breadcrumb li a:hover, 
.accordion.icons .title a:hover, 
.accordion.default .title a:hover,
.accordion.default .title.active a, 
.accordion.icons .title.active a, 
.accordion.default.simple.faq-2 .title:hover::before, 
.accordion.default.simple.faq-2 .title.active::before, 
.btn.empty span, 
.fa-ul.colored i, 
.fa-ul.large-icons .li-content h1:hover,
.fa-ul.large-icons .li-content h2:hover,
.fa-ul.large-icons .li-content h3:hover,
.fa-ul.large-icons .li-content h4:hover,
.fa-ul.large-icons .li-content h5:hover,
.fa-ul.large-icons .li-content h6:hover, 
.custom-background a:hover, 
.numbers-counter.theme-color i,
.custom-background .numbers-counter.theme-color i,
.page-content.dark .numbers-counter.theme-color i,
.post-body .post-meta li,
.post-body h2:hover,
.post-meta li a:hover,
.light .fancy-heading .heading-top,
.service-box-2 i,
.widget_nav_menu .menu-menu-container .sub-menu .menu-item.current-menu-item a,
.team-horizontal .team-member .team-member-details .member-position,
.service-box-3 i,
.service-box-3 a:hover h4,
.service-box-5 a:hover h4,
.service-box-6 .icon-container i,
.service-box-6 a:hover h4,
.service-box-7 .icon-container i,
.service-box-7 a:hover h4,
.service-box-8 .icon-container i,
.service-box-8 a:hover h4,
.service-box-9 .icon-container i,
.service-box-9 a:hover h4,
.service-box-10 .service-detail-container a:hover h4,
.service-box-13:hover i,
.pricing-table-col .head .title-container .title i,
#filters li.active a,
.portfolio-item-container figcaption a.title:hover,
.gallery-item.hover-caption figcaption a:hover,
.post-info .post-date .day,
.blog-post .post-comment-number i,
.rpw_posts_widget li a:hover h4,
.widget_recent_comments .comment-inner i,
.widget_recent_comments .comment-inner .comment a,
.comment-meta li.date,
.comment .comment-reply-link:hover,
.blog-post-box .post-body a:hover h3,
.rpw_posts_widget.style-2 .body a:hover h3,
.rpw_posts_widget.style-2 .body .comment-number:hover,
.header-info-widgets li .icon-container{
    color: #117dc2;
}

/* LIGHTER COLOR */
.page-title-5 .title-container .title h3{
    color: #4aafef;
}

.service-box-1 .title-container .icon, 
.widget_newsletterwidget .newsletter .submit, 
.accordion.default .title.active::before, 
.accordion.default .title:hover::before, 
.accordion.icons .title:hover .icon-container, 
.accordion.icons .title.active .icon-container, 
.btn.empty::before, 
.wpcf7-submit, 
.dropcap, 
.fa-ul.large-icons.theme-color-icons .icon-container i, 
.numbers-counter.small-icons i, 
.blockquote-style-2.theme-color,
.nivo-wrapper .nivo-directionNav a:hover,
.social-links.theme-bkg li:hover,
.rotator.theme-bkg,
.process-perspective .icon .circle-up,
.hint--theme-color:after,
.counter.theme-color .county-days-wrapper,
.page-content.parallax.dark .counter.theme-color .county-days-wrapper,
.page-content.parallax .counter.theme-color .county-days-wrapper, 
.page-content.custom-background .counter.theme-color .county-days-wrapper,
.process-box-container .process-box.box-1,
.owl-carousel:hover .owl-controls .owl-nav .owl-prev:hover,
.owl-carousel:hover .owl-controls .owl-nav .owl-next:hover,
.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span,
.history-list .history-list-item .year-img-container .year,
.team-member .member-social li .icon,
.scroll-up:hover,
.breadcrumbs-container.theme-color,
.page-title-3 .title-container .pt-info .icon i,
.page-title-7,
.expand,
.team-hover .team-member figcaption,
.process-box-circle .icon-container,
.service-box-4 .icon-container,
.btn.empty::after,
.btn,
.service-box-5:hover .icon-container,
.service-box-8:hover .icon-container,
.service-box-10 .icon-container,
.service-box-11:hover,
.list-icons-merged li:nth-child(2n + 1) .icon,
.pricing-table-col.selected .head .title-container,
.pricing-table-2-col.selected .price,
#coming-soon .newsletter .submit,
#filters li.active .item-number,
#filters li:hover .item-number,
.portfolio-details-list .mark,
.pagination li:hover a, 
.pagination li.active a,
.portfolio-item-container .portfolio-img.hover-alt .mask-style-2 li:hover,
.pixely_widget_sharre .box .share:hover,
.rpw_posts_widget li .comment-container .date,
.blog-post-box .comment-container .date,
.widget_tag_cloud a:hover,
.widget_recent_comments .date,
#commentform .comment-reply,
.rpw_posts_widget.style-2 .month,
.header-type-2 .navbar-nav>li>a:hover,
#header.header-type-2 .navbar-default .navbar-nav > .current-menu-item > a{
    background-color: #117dc2;
}

.newsletter .submit:hover, 
.wpcf7-submit:hover,
#commentform .comment-reply:hover{
    background-color: #1491e1;
}

.section-title-container .section-title,
.section-title-container .section-title::before{
    background-color: rgba(17, 125, 194, 0.7);
}

.service-box-12:hover .icon-container i{
    color: rgba(17, 125, 194, 0.3);
}

.counter.theme-color .county-hours-wrapper, 
.page-content.parallax .counter.theme-color .county-hours-wrapper,
.page-content.parallax.dark .counter.theme-color .county-hours-wrapper,
.page-content .custom-background .counter.theme-color .county-hours-wrapper,
.process-box-container .process-box.box-2,
.skills li:nth-child(2n) .expand,
.list-icons-merged li:nth-child(2n) .icon{
    background-color: #1491e1;
}

.counter.theme-color .county-minutes-wrapper,
.page-content.parallax .counter.theme-color .county-minutes-wrapper,
.page-content.parallax.dark .counter.theme-color .county-minutes-wrapper,
.page-content.custom-background .counter.theme-color .county-minutes-wrapper,
.process-box-container .process-box.box-3,
.skills li:nth-child(3n) .expand{
    background-color: #2ba1ec;
}

.counter.theme-color .county-seconds-wrapper,
.page-content.parallax .counter.theme-color .county-seconds-wrapper,
.page-content.parallax.dark .counter.theme-color .county-seconds-wrapper,
.page-content.custom-background .counter.theme-color .county-seconds-wrapper,
.process-box-container .process-box.box-4,
.page-title-5 .title-container .title:after,
.skills li:nth-child(4n) .expand,
.rpw_posts_widget li .comment-container .comment-number,
.blog-post-box .comment-container .comment-number{
    background-color: #4aafef;
}


/*  BORDERS
============================================================================= */
.tabs li.active,
#header.header-type-1 .navbar-default .navbar-nav > .current-menu-item > a, 
#header.header-type-1 .navbar-default .navbar-nav > li>a:hover, 
#header.header-type-1 .navbar-default .navbar-nav > li >a:focus,
.hint--theme-color.hint--top:before{
    border-top-color: #117dc2;
}

.blockquote-style-1,
.tabs.vertical li.active,
.hint--theme-color.hint--left:before,
.process-box-container .process-box:after,
.widget_nav_menu .menu-menu-container .menu-item.current-menu-item{
    border-left-color: #117dc2;
}

.btn.empty,
.dropcap.empty,
.hr-simple.colored .hr-simple-circle, 
.rotator.bordered.theme-color-border,
.btn.empty,
#coming-soon .newsletter .submit,
.pagination li:hover a, 
.pagination li.active a{
    border-color: #117dc2;
}

.hint--theme-color.hint--bottom:before{
    border-bottom-color: #117dc2;
}

.hint--theme-color.hint--right:before {
    border-right-color: #117dc2;
}

.process-box-container .process-box.box-2:after{
    border-left-color: #1491e1;
}

.process-box-container .process-box.box-3:after{
    border-left-color: #2ba1ec;
}

.process-box-container .process-box.box-4:after{
    border-left-color: #4aafef;
}


/*  28% DARKER COLOR - YOU CAN FIND CUSTOM DARKER COLOR HERE http://www.0to255.com/, 
    INSERT YOU CUSTOM COLOR AND CHOOSE 28% DARKER COLOR
============================================================================= */
.process-perspective .icon .circle-inner{
    background-color: #0c5584;
}


