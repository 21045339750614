@font-face {
	font-family: 'linecons';
	src:url('../fonts/linecons.eot?-9nih4g');
	src:url('../fonts/linecons.eot?#iefix-9nih4g') format('embedded-opentype'),
		url('../fonts/linecons.woff?-9nih4g') format('woff'),
		url('../fonts/linecons.ttf?-9nih4g') format('truetype'),
		url('../fonts/linecons.svg?-9nih4g#linecons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="linecons-"], [class*=" linecons-"] {
	font: normal normal normal 14px/1 linecons;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.linecons-adjustments:before {
	content: "\e600";
}
.linecons-alarmclock:before {
	content: "\e601";
}
.linecons-anchor:before {
	content: "\e602";
}
.linecons-aperture:before {
	content: "\e603";
}
.linecons-attachments:before {
	content: "\e604";
}
.linecons-bargraph:before {
	content: "\e605";
}
.linecons-basket:before {
	content: "\e606";
}
.linecons-beaker:before {
	content: "\e607";
}
.linecons-book-open:before {
	content: "\e608";
}
.linecons-briefcase:before {
	content: "\e609";
}
.linecons-browser:before {
	content: "\e60a";
}
.linecons-calendar:before {
	content: "\e60b";
}
.linecons-camera:before {
	content: "\e60c";
}
.linecons-caution:before {
	content: "\e60d";
}
.linecons-chat:before {
	content: "\e60e";
}
.linecons-circle-compass:before {
	content: "\e60f";
}
.linecons-clipboard:before {
	content: "\e610";
}
.linecons-clock:before {
	content: "\e611";
}
.linecons-cloud:before {
	content: "\e612";
}
.linecons-compass:before {
	content: "\e613";
}
.linecons-desktop:before {
	content: "\e614";
}
.linecons-dial:before {
	content: "\e615";
}
.linecons-document:before {
	content: "\e616";
}
.linecons-documents:before {
	content: "\e617";
}
.linecons-download:before {
	content: "\e618";
}
.linecons-dribbble:before {
	content: "\e619";
}
.linecons-edit:before {
	content: "\e61a";
}
.linecons-envelope:before {
	content: "\e61b";
}
.linecons-expand:before {
	content: "\e61c";
}
.linecons-facebook:before {
	content: "\e61d";
}
.linecons-flag:before {
	content: "\e61e";
}
.linecons-focus:before {
	content: "\e61f";
}
.linecons-gears:before {
	content: "\e620";
}
.linecons-genius:before {
	content: "\e621";
}
.linecons-gift:before {
	content: "\e622";
}
.linecons-global:before {
	content: "\e623";
}
.linecons-globe:before {
	content: "\e624";
}
.linecons-googleplus:before {
	content: "\e625";
}
.linecons-grid:before {
	content: "\e626";
}
.linecons-happy:before {
	content: "\e627";
}
.linecons-hazardous:before {
	content: "\e628";
}
.linecons-heart:before {
	content: "\e629";
}
.linecons-hotairballoon:before {
	content: "\e62a";
}
.linecons-hourglass:before {
	content: "\e62b";
}
.linecons-key:before {
	content: "\e62c";
}
.linecons-laptop:before {
	content: "\e62d";
}
.linecons-layers:before {
	content: "\e62e";
}
.linecons-lifesaver:before {
	content: "\e62f";
}
.linecons-lightbulb:before {
	content: "\e630";
}
.linecons-linegraph:before {
	content: "\e631";
}
.linecons-linkedin:before {
	content: "\e632";
}
.linecons-lock:before {
	content: "\e633";
}
.linecons-magnifying-glass:before {
	content: "\e634";
}
.linecons-map:before {
	content: "\e635";
}
.linecons-map-pin:before {
	content: "\e636";
}
.linecons-megaphone:before {
	content: "\e637";
}
.linecons-mic:before {
	content: "\e638";
}
.linecons-mobile:before {
	content: "\e639";
}
.linecons-newspaper:before {
	content: "\e63a";
}
.linecons-notebook:before {
	content: "\e63b";
}
.linecons-paintbrush:before {
	content: "\e63c";
}
.linecons-paperclip:before {
	content: "\e63d";
}
.linecons-pencil:before {
	content: "\e63e";
}
.linecons-phone:before {
	content: "\e63f";
}
.linecons-picture:before {
	content: "\e640";
}
.linecons-pictures:before {
	content: "\e641";
}
.linecons-piechart:before {
	content: "\e642";
}
.linecons-presentation:before {
	content: "\e643";
}
.linecons-pricetags:before {
	content: "\e644";
}
.linecons-printer:before {
	content: "\e645";
}
.linecons-profile-female:before {
	content: "\e646";
}
.linecons-profile-male:before {
	content: "\e647";
}
.linecons-puzzle:before {
	content: "\e648";
}
.linecons-quote:before {
	content: "\e649";
}
.linecons-recycle:before {
	content: "\e64a";
}
.linecons-refresh:before {
	content: "\e64b";
}
.linecons-ribbon:before {
	content: "\e64c";
}
.linecons-rss:before {
	content: "\e64d";
}
.linecons-sad:before {
	content: "\e64e";
}
.linecons-scissors:before {
	content: "\e64f";
}
.linecons-scope:before {
	content: "\e650";
}
.linecons-search:before {
	content: "\e651";
}
.linecons-shield:before {
	content: "\e652";
}
.linecons-speedometer:before {
	content: "\e653";
}
.linecons-strategy:before {
	content: "\e654";
}
.linecons-streetsign:before {
	content: "\e655";
}
.linecons-tablet:before {
	content: "\e656";
}
.linecons-telescope:before {
	content: "\e657";
}
.linecons-toolbox:before {
	content: "\e658";
}
.linecons-tools:before {
	content: "\e659";
}
.linecons-tools-2:before {
	content: "\e65a";
}
.linecons-traget:before {
	content: "\e65b";
}
.linecons-trophy:before {
	content: "\e65c";
}
.linecons-tumblr:before {
	content: "\e65d";
}
.linecons-twitter:before {
	content: "\e65e";
}
.linecons-upload:before {
	content: "\e65f";
}
.linecons-video:before {
	content: "\e660";
}
.linecons-wallet:before {
	content: "\e661";
}
.linecons-wine:before {
	content: "\e662";
}
